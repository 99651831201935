@import "/styles/variables.scss";
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-y: auto;
  font-family: 'Poppins', sans-serif;
  background-color: #F2F2F2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
textarea {
  font-family: unset;
  font-size: unset;
  font-family: 'Poppins', sans-serif;
}

h1,h2,h4,h5,h6 {
  font-weight: bold;
}

h1 {
  color:rgba(199,199,199,.6);
}

.cta {
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  text-decoration: none;
}

.primaryCta {
  color: white;
  background-color: $primary-blue;
  border-color: $primary-blue;
}

.secondaryCta {
  color: $primary-yellow;
  background-color: transparent;
  border-color: $primary-yellow;
}

@media screen and (min-width: 0px){

  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  .cta {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 8px 12px;
  }

  .cta svg {
    font-size: 18px;
    margin-bottom: -3px;
    margin-left: 5px;
  }

}

@media screen and (min-width: 500px){
  
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 14px;
  }  

  .cta {
    border-width: 2px;
    border-style: solid;
    padding: 5px 14px;
  }

  .cta svg {
    font-size: 20px;
    margin-bottom: -3px;
    margin-left: 6px;
  }

}

@media screen and (min-width: 950px){

  h1 {
    font-size: 98px;
  }

  h2 {
    font-size: 60px;
  }

  h3 {
    font-size: 45px;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  .cta {
    border-width: 2px;
    border-style: solid;
    border-radius: 6px;
    padding: 6px 15px;
  }

  .cta svg {
    font-size: 25px;
    margin-bottom: -5px;
    margin-left: 6px;
  }
    
}