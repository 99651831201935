@import "/styles/variables.scss";
.Layout {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
}

@media screen and (min-width: 0px){

  .Layout {
    -ms-grid-rows: 60px 1fr auto;
    grid-template-rows: 60px 1fr auto;
  }

}

@media screen and (min-width: 500px){

  .Layout {
    -ms-grid-rows: 90px 1fr auto;
    grid-template-rows: 90px 1fr auto;
  }

}

@media screen and (min-width: 950px){
  
  .Layout {
    -ms-grid-rows: 154px 1fr auto;
    grid-template-rows: 154px 1fr auto;  
  }

}